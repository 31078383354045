import { useState, useRef, useContext, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    color: "black",
    display: "flex",
}));
export default function (props) {
    const { platformType } = props;
    return <Box width={"50%"} marginX={1}>
        <Item
            style={{
                backgroundColor: "white",
                fontWeight: "bold",
                display: "flex",
                flexFlow: "column",
                height: "78vh",
                padding: "1% 2%",
                width: "95.5%",
            }}
        >
            <Box height={"150px"}><p
                style={{
                    margin: "auto",
                    padding: "20px",
                    display: "block",
                    fontSize: "20px",
                }}
            >
                Holding Rebate Execution History -{" "}
                {platformType == "TU" ? "Tradeultra" : "Century"}
            </p>
                <Divider
                    sx={{ width: "70%", margin: "auto", backgroundColor: "#ffd700" }}
                />
            </Box>
        </Item>
    </Box>
}