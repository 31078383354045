import { useState, useRef, useContext, useEffect } from "react";
import ExecutionHistory from "./executionHistory";
import ExecutionLogs from "./executionLogs";
import { Box, Typography, Grid } from "@mui/material";
import { Tooltip } from "antd";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? "#04832a" : "#04832a",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));
export default function (props) {
    const [displayPlatformToggle, setDisplayPlatformToggle] = useState(true);
    const [platformType, setPlatformType] = useState("TU");
    const togglePlatformType = () => {
        if (platformType == "TU") setPlatformType("CFC");
        else setPlatformType("TU");
    };
    return (
        <>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Grid
                    container
                    spacing={5}
                    p={3}
                    mb={3}
                    justifyContent="center"
                    sx={{ backgroundColor: "#ffd700" }}
                >
                    <Grid item lg={9} xl={9} md={9} sm={9} xs={9}>
                        <Typography style={{ fontWeight: 700, fontSize: 24 }}>
                            Execution History
                        </Typography>
                    </Grid>
                    <Grid item lg={3} xl={3} md={3} sm={3} xs={3}>
                        {displayPlatformToggle && (
                            <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="end"
                                alignItems="center"
                                pl={"15px"}
                            >
                                <Typography style={{ fontWeight: 700 }}>CENTURY</Typography>
                                <Tooltip title={false ? "Data is Loading" : ""}>
                                    <span><AntSwitch
                                        disabled={false}
                                        checked={platformType == "TU" ? true : false}
                                        onChange={(checked) => {
                                            togglePlatformType();
                                        }}
                                        inputProps={{ "aria-label": "ant design" }}
                                    /></span>
                                </Tooltip>
                                <Typography style={{ fontWeight: 700 }}>TU</Typography>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Box >
            <Box sx={{ display: "flex", width: "100%" }}>
                <ExecutionHistory platformType={platformType} />
                <ExecutionLogs platformType={platformType} />
            </Box>
        </>
    );
}
