import payloadEncryptor from "./payloadEncryptor";

export default async function encryptStringInChunks(str, chunkSize = 501) {
  console.log("before", str);
  if (typeof str == "object") {
    str = JSON.stringify(str);
  }
  console.log("after", str);
  let encryptedChunks = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    try {
      encryptedChunks.push(await payloadEncryptor(str.slice(i, i + chunkSize)));
    } catch (e) {
      console.log(e.message, i);
    }
  }
  console.log("encrypted Array", encryptedChunks);
  return encryptedChunks;
}
