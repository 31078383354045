import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { Table, Space, Button, Grid, Tooltip, Alert, Modal } from "antd";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewChangesModal from "../viewChangesModal";
import TableSkeleton from "../tableSkeleton";
import AssetType from "./assetType";
import SubAsset from "./subAsset";
import Group from "./group";
import Symbol from "./symbol";
import Description from "./description";
import MinVol from "./minVol";
import MaxVol from "./maxVol";
import VolLimit from "./volLimit";
import ContractSpecs from "./contractSpecs";
import PopularProductRank from "./popularProductRank";
import ISIN from "./isin";
import RicCode from "./ricCode";
import ProductCode from "./productCode";
import ProductName from "./productName";
import Ticker from "./ticker";
import Ric from "./ric";
import Exchange from "./exchange";
import ID from "./id";
const { useBreakpoint } = Grid;

export default function ImportResult(props) {
  const [modal, contextHolder] = Modal.useModal();

  const {
    importedRows,
    filename,
    isImporting,
    uploadFile,
    resultType,
    errors,
    setErrors,
    errorIndex,
    changes,
    duplicateSymbolObject,
    duplicateAssetCombinationObject
  } = props;
  const screens = useBreakpoint();
  const [scrollHeight, setScrollHeight] = useState(400);
  const [changesModalVisible, setChangesModalVisible] = useState(false);
  const [confirmUploadMsg, setConfirmUploadMsg] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const columns = [
    {
      title: "Serial",
      dataIndex: "sr_no",
      width: 100,
      render: (x) => <div id={"sr" + x}>{x}</div>,
    },
    {
      title: "Asset Type",
      width: 150,
      render: (row) => <AssetType setErrors={setErrors} {...row} duplicateAssetCombinationObject={duplicateAssetCombinationObject} />,
    },
    {
      title: "Sub Asset",
      width: 150,
      render: (row) => <SubAsset setErrors={setErrors} {...row} />,
    },
    {
      title: "Group",
      width: 150,
      render: (row) => <Group setErrors={setErrors} {...row} />,
    },
    {
      title: "Symbol",
      width: 150,
      render: (row) => <Symbol setErrors={setErrors} {...row} />,
    },
    {
      title: "Description",
      width: 150,
      render: (row) => <Description setErrors={setErrors} {...row} />,
    },
    {
      title: "Min Vol",
      width: 150,
      render: (row) => <MinVol setErrors={setErrors} {...row} />,
    },
    {
      title: "Max Vol",
      width: 150,
      render: (row) => <MaxVol setErrors={setErrors} {...row} />,
    },
    {
      title: "Vol Limit",
      width: 150,
      render: (row) => <VolLimit setErrors={setErrors} {...row} />,
    },
    {
      title: "Contract Specs",
      width: 150,
      render: (row) => <ContractSpecs setErrors={setErrors} {...row} />,
    },
    {
      title: "Popular product rank",
      width: 150,
      render: (row) => <PopularProductRank setErrors={setErrors} {...row} duplicateSymbolObject={duplicateSymbolObject} />,
    },
    {
      title: "ISIN",
      width: 150,
      render: (row) => <ISIN setErrors={setErrors} {...row} />,
    },
    {
      title: "Ric Code",
      width: 150,
      render: (row) => <RicCode setErrors={setErrors} {...row} />,
    },
    {
      title: "Product Code",
      width: 150,
      render: (row) => <ProductCode setErrors={setErrors} {...row} />,
    },
    {
      title: "Product Name",
      width: 150,
      render: (row) => <ProductName setErrors={setErrors} {...row} />,
    },
    {
      title: "Ticker",
      width: 150,
      render: (row) => <Ticker setErrors={setErrors} {...row} />,
    },
    {
      title: "RIC",
      width: 150,
      render: (row) => <Ric setErrors={setErrors} {...row} />,
    },
    {
      title: "Exchange",
      width: 150,
      render: (row) => <Exchange setErrors={setErrors} {...row} />,
    },
    {
      title: "ID",
      width: 150,
      render: (row) => <ID setErrors={setErrors} {...row} />,
    },
  ];
  function closeViewChangesModal() {
    setChangesModalVisible(false);
  }

  function openViewChangesModal() {
    setChangesModalVisible(true);
  }
  useEffect(() => {
    if (screens.xs) {
      setScrollHeight(600);
    } else {
      setScrollHeight(getHeight());
    }
  }, [screens]);

  function getHeight() {
    return (
      document.getElementById("resultBox").parentElement.offsetHeight - 200
    );
  }

  function TableTitle(props) {
    return (
      <Space size={"large"}>
        <Box>
          File : <span style={{ color: "blue" }}>{filename}</span>
        </Box>
        <Box>
          <span style={{ color: "gray" }}>Total Symbols</span> :{" "}
          {importedRows.length}
        </Box>
        <Box>
          <span style={{ color: "red" }}>Errors</span> : {errors.length}
        </Box>
        {Object.keys(changes).length > 0 && (
          <Box>
            <span style={{ color: "teal" }}>Changes</span> :{" "}
            {Object.keys(changes).length}{" "}
            <Tooltip title="View Changes">
              <IconButton size="small" onClick={openViewChangesModal}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {errors.length > 0 && (
          <Space>
            <Tooltip title={"Jump to error downward"}>
              <IconButton
                size="small"
                onClick={(e) => {
                  try {
                    if (errorIndex.current < errors.length) {
                      highlightError(
                        errors[errorIndex.current + 1],
                        errors[errorIndex.current],
                        "down"
                      );
                      errorIndex.current = errorIndex.current + 1;
                    } else {
                      errorIndex.current = 0;
                    }
                  } catch (e) {
                    console.log("Jump Downward Error:", e.message);
                  }
                }}
              >
                <ArrowDownwardIcon style={{ color: "red" }} fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Jump to error upward"}>
              <IconButton
                size="small"
                onClick={(e) => {
                  try {
                    if (errorIndex.current >= 0) {
                      highlightError(
                        errors[errorIndex.current - 1],
                        errors[errorIndex.current],
                        "up"
                      );
                      errorIndex.current = errorIndex.current - 1;
                    } else {
                      errorIndex.current = errors.length - 1;
                    }
                  } catch (e) {
                    console.log("Jump Upward Error:", e.message);
                  }
                }}
              >
                <ArrowUpwardIcon style={{ color: "red" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Space>
        )}

        {resultType === "imported" && errors.length === 0 && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              let length = Object.keys(changes).length;
              if (length > 0) {
                setConfirmUploadMsg(
                  `There are ${length} changes in the file. Are you sure you want to proceed with these changes and upload the file?`
                );
              } else {
                uploadFile();
                setConfirmUploadMsg(false);
              }
            }}
          >
            Upload
          </Button>
        )}
        {resultType === "imported" && errors.length > 0 && (
          <Alert
            message={"Please fix these errors and import the file again"}
            showIcon
            type="warning"
          />
        )}
      </Space>
    );
  }

  return (
    <div id={"resultBox"}>
      <Modal
        title={"Confirm changes"}
        open={confirmUploadMsg}
        onOk={() => {
          uploadFile();
          setConfirmUploadMsg(false);
        }}
        onCancel={() => setConfirmUploadMsg(false)}
        okText="Yes"
        cancelText="No"
      >
        <Box sx={{ marginTop: 4 }}>{confirmUploadMsg}</Box>
      </Modal>

      <ViewChangesModal
        open={changesModalVisible}
        onClose={closeViewChangesModal}
        changes={changes}
      />
      {!isImporting && importedRows.length > 0 && (
        <Table
          dataSource={importedRows}
          columns={columns}
          pagination={false}
          scroll={{
            y: scrollHeight
          }}
          sx={{ overflow: "auto" }}
          title={() => <TableTitle />}
        />
      )}

      {isImporting && (
        <TableSkeleton scrollHeight={scrollHeight} resultType={resultType} />
      )}

      {!isImporting && importedRows.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            fontSize: {
              lg: 56,
              xl: 56,
              md: 56,
              sm: 30,
              xs: 30,
            },
            color: "#dbdbdb",
            fontWeight: "bold",
            padding: {
              lg: 16,
              xl: 16,
              md: 16,
              sm: 4,
              xs: 4,
            },
          }}
        >
          Import a file to see the results here
        </Box>
      )}
    </div>
  );
}

function highlightError(elementId, previousId, direction) {
  try {
    let element = document.getElementById(elementId);
    element.scrollIntoView({ block: "center" });
    element.style.border = "1px solid red";
  } catch (e) {
    console.log("Highlight elementId error : ", e.message);
  }

  try {
    document.getElementById(previousId).style.border = "none";
  } catch (e) {
    console.log("Highlight previousId error : ", e.message);
  }
}
