import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Grid, CircularProgress, Typography, Paper } from "@mui/material";

import {
  List,
  message,
  Skeleton,
  Avatar,
  Space,
  Divider,
  Empty,
  Tooltip
} from "antd";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ImportResult from "./ImportResult/index.js";
import CSVReader from "react-csv-reader";
import { styled } from "@mui/material/styles";
import TableSkeleton from "./tableSkeleton";
// import {
//   FETCH_SYMBOL_MASTER,
//   GET_UPLOADED_SYMBOL_MASTERS,
//   UPLOAD_SYMBOL_MASTER,
// } from "../../../helpers/adminApiStrings";
import axios from "axios";
import moment from "moment";
import UserRoleConext from "../user_roles/userRoleContext.js";
import { usePapaParse } from "react-papaparse";
import UploadHistory from "./uploadHistory.js";
import { FETCH_SYMBOL_MASTER, UPLOAD_SYMBOL_MASTER } from "../../helper/apiString.js";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
export default function SymbolMasterUpload(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { readRemoteFile } = usePapaParse();

  const [importedRows, setImportedRows] = useState([]);
  const [filename, setFilename] = useState("");
  const [isImporting, setIsImporting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);
  const [scrollHeight, setScrollHeight] = useState(400);
  const [errors, setErrors] = useState({
    tempErrors: [],
    duplicateSymbolObject: {},
  });
  const [calledFrom, setCalledFrom] = useState("uploadFile");
  const [changes, setChanges] = useState({});
  const errorIndex = useRef(0);
  const userContext = useContext(UserRoleConext);
  const [resultType, setResultType] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const togglePlatformType = () => {
    // resetFilter();
    // setReloadData(true);
    if (userContext.platformType == "TU") {
      userContext.setPlatformType("CFC");
    } else {
      userContext.setPlatformType("TU");
    }
  };
  function onImport(data, fileInfo) {
    try {
      if (lastUploadedFile == null) {
        setIsImporting(true);
        setErrors(getErrors(data));
        setImportedRows(data);
        setFilename(fileInfo.name);
        setResultType("imported");
        setIsImporting(false);
      } else {
        readRemoteFile(
          FETCH_SYMBOL_MASTER + "/" + lastUploadedFile.uploadedName,
          {
            complete: (results) => {
              let oldData = results.data;
              let temp = {};
              let tempChanges = {};

              // creating JSON map for change comparison

              for (let i = 0; i < oldData.length; i++) {
                temp[oldData[i].symbol] = oldData[i];
              }

              // // creating JSON map for change comparison
              for (let i = 0; i < data.length; i++) {
                let key = data[i].symbol;

                if (temp.hasOwnProperty(key)) {
                  tempChanges[key] = { old: {}, current: {} };

                  if (temp[key].group !== data[i].group) {
                    tempChanges[key].old["group"] = temp[key].group;
                    tempChanges[key].current["group"] = data[i].group;
                  }

                  if (temp[key].asset_type !== data[i].asset_type) {
                    tempChanges[key].old["asset_type"] = temp[key].asset_type;
                    tempChanges[key].current["asset_type"] = data[i].asset_type;
                  }
                  if (temp[key]._sub_asset !== data[i]._sub_asset) {
                    tempChanges[key].old["_sub_asset"] = temp[key]._sub_asset;
                    tempChanges[key].current["_sub_asset"] = data[i]._sub_asset;
                  }
                  if (temp[key].symbol !== data[i].symbol) {
                    tempChanges[key].old["symbol"] = temp[key].symbol;
                    tempChanges[key].current["symbol"] = data[i].symbol;
                  }
                  if (temp[key].description !== data[i].description) {
                    tempChanges[key].old["description"] = temp[key].description;
                    tempChanges[key].current["description"] = data[i].description;
                  }
                  if (temp[key].min_vol !== data[i].min_vol) {
                    tempChanges[key].old["min_vol"] = temp[key].min_vol;
                    tempChanges[key].current["min_vol"] = data[i].min_vol;
                  }
                  if (temp[key].max_vol !== data[i].max_vol) {
                    tempChanges[key].old["max_vol"] = temp[key].max_vol;
                    tempChanges[key].current["max_vol"] = data[i].max_vol;
                  }
                  if (temp[key].vol_limit !== data[i].vol_limit) {
                    tempChanges[key].old["vol_limit"] = temp[key].vol_limit;
                    tempChanges[key].current["vol_limit"] = data[i].vol_limit;
                  }
                  if (temp[key].contract_specs !== data[i].contract_specs) {
                    tempChanges[key].old["contract_specs"] = temp[key].contract_specs;
                    tempChanges[key].current["contract_specs"] = data[i].contract_specs;
                  }
                  if (temp[key].popular_product_rank !== data[i].popular_product_rank) {
                    tempChanges[key].old["popular_product_rank"] = temp[key].popular_product_rank;
                    tempChanges[key].current["popular_product_rank"] = data[i].popular_product_rank;
                  }
                  if (temp[key].isin !== data[i].isin) {
                    tempChanges[key].old["isin"] = temp[key].isin;
                    tempChanges[key].current["isin"] = data[i].isin;
                  }
                  if (temp[key].ric_code !== data[i].ric_code) {
                    tempChanges[key].old["ric_code"] = temp[key].ric_code;
                    tempChanges[key].current["ric_code"] = data[i].ric_code;
                  }
                  if (temp[key].product_code !== data[i].product_code) {
                    tempChanges[key].old["product_code"] = temp[key].product_code;
                    tempChanges[key].current["product_code"] = data[i].product_code;
                  }
                  if (temp[key].product_name !== data[i].product_name) {
                    tempChanges[key].old["product_name"] = temp[key].product_name;
                    tempChanges[key].current["product_name"] = data[i].product_name;
                  }
                  if (temp[key].ticker !== data[i].ticker) {
                    tempChanges[key].old["ticker"] = temp[key].ticker;
                    tempChanges[key].current["ticker"] = data[i].ticker;
                  }
                  if (temp[key].ric !== data[i].ric) {
                    tempChanges[key].old["ric"] = temp[key].ric;
                    tempChanges[key].current["ric"] = data[i].ric;
                  }
                  if (temp[key].exchange !== data[i].exchange) {
                    tempChanges[key].old["exchange"] = temp[key].exchange;
                    tempChanges[key].current["exchange"] = data[i].exchange;
                  }
                  if (temp[key].id !== data[i].id) {
                    tempChanges[key].old["id"] = temp[key].id;
                    tempChanges[key].current["id"] = data[i].id;
                  }

                  // if (Number(temp[key].digits) !== Number(data[i].digits)) {
                  //   tempChanges[key].old["digits"] = Number(temp[key].digits);
                  //   tempChanges[key].current["digits"] = Number(data[i].digits);
                  // }

                  if (
                    Object.keys(tempChanges[key].old).length == 0 &&
                    Object.keys(tempChanges[key].current).length == 0
                  ) {
                    delete tempChanges[key];
                  }
                } else {
                  tempChanges[key] = {
                    current: data[i],
                    new: true,
                    old: {
                      asset_type: "",
                      sub_asset: "",
                      symbol: "",
                      group: "",
                      description: "",
                      min_vol: "",
                      max_vol: "",
                      vol_limit: "",
                      contract_specs: "",
                      popular_product_rank: "",
                      isin: "",
                      ric_code: "",
                      ric: "",
                      product_name: "",
                      product_code: "",
                      ticker: "",
                      exchange: "",
                      id: ""
                    },
                  };
                }
              }

              setCalledFrom("uploadFile");
              setIsImporting(true);
              // checking for errors
              setErrors(getErrors(data));
              setChanges(tempChanges);
              setImportedRows(data);
              setFilename(fileInfo.name);
              setIsImporting(false);
            },
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) =>
              header.toLowerCase().replace(/\W/g, "_"),
          }
        );
      }
    } catch (e) {
      console.log("Import Failed: ", e.message);
      messageApi.open({
        type: "error",
        content: "Invalid file. Could not import",
      });
    }
  }

  function uploadFile() {
    setIsUploading(true);
    var formData = new FormData();
    console.log(changes)
    formData.append("symbolMaster", fileToUpload);
    formData.append("changes", JSON.stringify(changes));
    formData.append("uploadedBy", "Mumshad");
    // formData.append("uploadedBy", localStorage.getItem("username"));
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    let axiosInstance = axios.create();
    axiosInstance
      .post(UPLOAD_SYMBOL_MASTER, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
      .then((res) => {
        console.log(uploadedFiles)
        console.log(res)
        setLastUploadedFile(uploadedFiles.length > 0 ? uploadedFiles[0] : null);
        setUploadedFiles((old) => {
          return [res.data, ...old];
        });
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateSymbolObject: {} });
        errorIndex.current = 0;
        setFileToUpload(null);
        messageApi.open({
          type: "success",
          content: "File uploaded successfully",
        });
        setIsUploading(false);
      })
      .catch((e) => {
        setIsUploading(false);
        console.log("Upload error :", e.message);
        messageApi.open({
          type: "error",
          content: "Could not upload file. Something went wrong.",
        });
      });
  }

  const height = {
    lg: "85vh",
    xl: "85vh",
    md: "85vh",
    sm: "auto",
    xs: "auto",
  };

  useEffect(() => {
    document.title = "Symbol Master Upload";
    // props.setDisplayPlatformToggle(false);
    document
      .getElementById("importDocument")
      .addEventListener("change", function (e) {
        setIsImporting(true);
        setFileToUpload(e.target.files[0]);
        setResultType("imported");
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateSymbolObject: {} });
        errorIndex.current = 0;
      });
    // checkTodaysFileAndMissingfiles();
  }, []);

  function viewUploadedSymbolMaster(
    uploadedName,
    originalName,
    uploadedChanges = {}
  ) {
    setResultType("uploaded");
    setIsImporting(true);
    setFilename(uploadedName);
    setImportedRows([]);
    setChanges(uploadedChanges);
    setErrors({ tempErrors: [], duplicateSymbolObject: {} });
    errorIndex.current = 0;

    readRemoteFile(FETCH_SYMBOL_MASTER + "/" + uploadedName, {
      complete: (results) => {
        setIsImporting(false);
        setCalledFrom("viewFile");
        setImportedRows(results.data);
      },
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    });
  }

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: "white",
        height: "89vh",
        width: "100%"
      }}
    >
      {contextHolder}
      <Grid
        container
        spacing={5}
        p={3}
        mb={3}
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#ffd700" }}
      >
        <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
          <Typography style={{ fontWeight: 700, fontSize: 24 }}>
            Symbol Master Uploader
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
          height: "10vh",
          mt: 2,
          mb: 0,
        }}
      >
        <Button
          size="large"
          variant="contained"
          onClick={(e) => {
            document.getElementById("importDocument").click();
          }}
        >
          <div hidden>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={onImport}
              onError={(error) => {
                console.log("Import Failed: ", error.message);
                messageApi.open({
                  type: "error",
                  content: "Invalid file. Could not import",
                });
              }}
              parserOptions={{
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header) =>
                  header.toLowerCase().replace(/\W/g, "_"),
              }}
              inputId="importDocument"
            />
          </div>
          Import File
        </Button>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Grid container>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box sx={{ height: height, position: "relative" }}>
              {!isImporting && importedRows.length === 0 && (
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: {
                      lg: 56,
                      xl: 56,
                      md: 56,
                      sm: 30,
                      xs: 30,
                    },
                    color: "#dbdbdb",
                    fontWeight: "bold",
                    padding: {
                      lg: 16,
                      xl: 16,
                      md: 16,
                      sm: 4,
                      xs: 4,
                    },
                  }}
                >
                  Import a file to see the results here
                </Box>
              )}
              {isImporting && (
                <TableSkeleton scrollHeight={scrollHeight} resultType={resultType} />
              )}
              {importedRows.length != 0 && (
                <ImportResult
                  importedRows={importedRows}
                  isImporting={isImporting}
                  filename={filename}
                  uploadFile={uploadFile}
                  errors={calledFrom == "viewFile" ? [] : errors.tempErrors}
                  setErrors={setErrors}
                  errorIndex={errorIndex}
                  resultType={resultType}
                  changes={changes}
                  duplicateSymbolObject={errors.duplicateSymbolObject}
                  duplicateAssetCombinationObject={errors.duplicateAssetCombinationObject}
                />
              )}
              {isUploading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(256,256,256,0.6)",
                    zIndex: 100,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <CircularProgress disableShrink size={150} />
                  <Box
                    sx={{
                      fontSize: {
                        lg: 56,
                        xl: 56,
                        md: 56,
                        sm: 30,
                        xs: 30,
                      },
                      color: "#dbdbdb",
                    }}
                  >
                    Uploading file...
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <UploadHistory
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              selectedFilename={filename}
              onViewFile={viewUploadedSymbolMaster}
              setLastUploadedFile={setLastUploadedFile}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function getErrors(data) {
  let tempErrors = [];
  let duplicateAssetCombinationObject = {};
  let checkDuplicateAssetCombinationArray = [];
  let duplicateSymbolObject = {};
  let checkDuplicateSymbolArray = [];
  for (let i = 0; i < data.length; i++) {
    let symbol = data[i].symbol;
    let sr_no = data[i].sr_no;


    // _asset_type
    try {
      if (data[i].asset_type.trim().length === 0) {
        tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
      }
      else if ((data[i].group.trim().toLowerCase()).includes("equity")) {
        if ((data[i].asset_type).toLowerCase() != "shares") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("forex m")) {
        if ((data[i].asset_type).toLowerCase() != "currencies") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("cryptocurrency")) {
        if ((data[i].asset_type).toLowerCase() != "crypto") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("forex forward")) {
        if ((data[i].asset_type).toLowerCase() != "currency forward") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("currency index")) {
        if ((data[i].asset_type).toLowerCase() != "currency indices") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("cfd index")) {
        if ((data[i].asset_type).toLowerCase() != "indices") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("treasuries")) {
        if ((data[i].asset_type).toLowerCase() != "treasuries") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("index")) {
        if ((data[i].asset_type).toLowerCase() != "indices") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("metals") || (data[i].group.trim().toLowerCase()).includes("agri") || (data[i].group.trim().toLowerCase()).includes("energy")) {
        if ((data[i].asset_type).toLowerCase() != "commodities") {
          tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        }
      }
    } catch (e) {
      tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
    }
    // _sub_asset
    try {
      if ((data[i].group.trim().toLowerCase()).includes("cryptocurrency")) {
        if (data[i].sub_asset != "" && data[i].sub_asset != undefined) {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("cash equity") || (data[i].group.toLowerCase()).includes("us equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "usa") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("denmark equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "denmark") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("euro equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "europe") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("hongkong equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "hongkong") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("norway equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "norway") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("swedish equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "sweden") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("switzerland equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "switzerland") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("uk equity")) {
        if (data[i].sub_asset.trim().toLowerCase() != "uk") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("energy1")) {
        if (data[i].sub_asset.trim().toLowerCase() != "futures") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("fix metal")) {
        if (data[i].sub_asset.trim().toLowerCase() != "cash") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("forex minors")) {
        if (data[i].sub_asset.trim().toLowerCase() != "minor") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
      else if ((data[i].group.trim().toLowerCase()).includes("metals gold 1")) {
        if (data[i].sub_asset.trim().toLowerCase() != "cash") {
          tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
        }
      }
    } catch (e) {
      tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
    }
    // LP Name Validation
    try {
      if (data[i].asset_type != null && (data[i].asset_type.trim().toLowerCase() == "cmc" || data[i].asset_type.trim().toLowerCase() == "gtn" || data[i].asset_type.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
      }
      else if (data[i].sub_asset != null && (data[i].sub_asset.trim().toLowerCase() == "cmc" || data[i].sub_asset.trim().toLowerCase() == "gtn" || data[i].sub_asset.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].sub_asset}${sr_no}_sub_asset`);
      }
      else if (data[i].symbol != null && (data[i].symbol.trim().toLowerCase() == "cmc" || data[i].symbol.trim().toLowerCase() == "gtn" || data[i].symbol.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].symbol}${sr_no}_symbol`);
      }
      else if (data[i].group != null && (data[i].group.trim().toLowerCase() == "cmc" || data[i].group.trim().toLowerCase() == "gtn" || data[i].group.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].group}${sr_no}_group`);
      }
      else if (data[i].description != null && (data[i].description.trim().toLowerCase() == "cmc" || data[i].description.trim().toLowerCase() == "gtn" || data[i].description.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].description}${sr_no}_description`);
      }
      else if (data[i].min_vol != null && (data[i].min_vol.trim().toLowerCase() == "cmc" || data[i].min_vol.trim().toLowerCase() == "gtn" || data[i].min_vol.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].min_vol}${sr_no}_min_vol`);
      }
      else if (data[i].max_vol != null && (data[i].max_vol.trim().toLowerCase() == "cmc" || data[i].max_vol.trim().toLowerCase() == "gtn" || data[i].max_vol.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].max_vol}${sr_no}_max_vol`);
      }
      else if (data[i].vol_limit != null && (data[i].vol_limit.trim().toLowerCase() == "cmc" || data[i].vol_limit.trim().toLowerCase() == "gtn" || data[i].vol_limit.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].vol_limit}${sr_no}_vol_limit`);
      }
      else if (data[i].contract_specs != null && (data[i].contract_specs.trim().toLowerCase() == "cmc" || data[i].contract_specs.trim().toLowerCase() == "gtn" || data[i].contract_specs.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].contract_specs}${sr_no}_contract_specs`);
      }
      else if (data[i].popular_product_rank != null && (data[i].popular_product_rank.trim().toLowerCase() == "cmc" || data[i].popular_product_rank.trim().toLowerCase() == "gtn" || data[i].popular_product_rank.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
      }
      else if (data[i].isin != null && (data[i].isin.trim().toLowerCase() == "cmc" || data[i].isin.trim().toLowerCase() == "gtn" || data[i].isin.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].isin}${sr_no}_isin`);
      }
      else if (data[i].ric_code != null && (data[i].ric_code.trim().toLowerCase() == "cmc" || data[i].ric_code.trim().toLowerCase() == "gtn" || data[i].ric_code.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].ric_code}${sr_no}_ric_code`);
      }
      else if (data[i].product_code != null && (data[i].product_code.trim().toLowerCase() == "cmc" || data[i].product_code.trim().toLowerCase() == "gtn" || data[i].product_code.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].product_code}${sr_no}_product_code`);
      }
      else if (data[i].product_name != null && (data[i].product_name.trim().toLowerCase() == "cmc" || data[i].product_name.trim().toLowerCase() == "gtn" || data[i].product_name.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].product_name}${sr_no}_product_name`);
      }
      else if (data[i].ticker != null && (data[i].ticker.trim().toLowerCase() == "cmc" || data[i].ticker.trim().toLowerCase() == "gtn" || data[i].ticker.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].ticker}${sr_no}_ticker`);
      }
      else if (data[i].ric != null && (data[i].ric.trim().toLowerCase() == "cmc" || data[i].ric.trim().toLowerCase() == "gtn" || data[i].ric.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].ric}${sr_no}_ric`);
      }
      else if (data[i].exchange != null && (data[i].exchange.trim().toLowerCase() == "cmc" || data[i].exchange.trim().toLowerCase() == "gtn" || data[i].exchange.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].exchange}${sr_no}_exchange`);
      }
      else if (data[i].id != null && (data[i].id.trim().toLowerCase() == "cmc" || data[i].id.trim().toLowerCase() == "gtn" || data[i].id.trim().toLowerCase() == "ig")) {
        tempErrors.push(`${data[i].id}${sr_no}_id`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].group}${sr_no}_group`);
    }
    // Group Validation
    try {
      if (data[i].group.trim().length === 0) {
        tempErrors.push(`${data[i].group}${sr_no}_group`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].group}${sr_no}_group`);
    }

    // Symbol Validation

    try {
      if (data[i].symbol.trim().length === 0) {
        tempErrors.push(`${symbol}${sr_no}_symbol`);
      }
    } catch (e) {
      tempErrors.push(`${symbol}${sr_no}_symbol`);
    }


    try {
      let duplicateConcatenated = data[i].asset_type + data[i].group + data[i].symbol
      if (checkDuplicateAssetCombinationArray.includes(duplicateConcatenated)) {
        tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
        duplicateAssetCombinationObject[i] = true;
      } else {
        duplicateAssetCombinationObject[i] = false;
        checkDuplicateAssetCombinationArray.push(duplicateConcatenated);
      }
    } catch (e) {
      tempErrors.push(`${data[i].asset_type}${sr_no}_asset_type`);
    }
    try {
      if (checkDuplicateSymbolArray.includes(data[i].popular_product_rank)) {
        tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
        duplicateSymbolObject[i] = true;
      } else {
        duplicateSymbolObject[i] = false;
        if (data[i].popular_product_rank != null)
          checkDuplicateSymbolArray.push(data[i].popular_product_rank);
      }
    } catch (e) {
      tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
    }




    //description
    // try {
    //   if (data[i].description.trim().length === 0) {
    //     tempErrors.push(`${data[i].description}${sr_no}_description`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].description}${sr_no}_description`);
    // }

    // //min_vol Validation
    // try {
    //   if (!typeof parseFloat(data[i].min_vol) || parseFloat(data[i].min_vol) === 0) {
    //     tempErrors.push(`${data[i].min_vol}${sr_no}_min_vol`);
    //   } else if (!data[i].min_vol) {
    //     tempErrors.push(`${data[i].min_vol}${sr_no}_min_vol`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].min_vol}${sr_no}_min_vol`);
    // }

    // //max_vol Validation
    // try {
    //   if (!typeof parseFloat(data[i].max_vol) || parseFloat(data[i].max_vol) === 0) {
    //     tempErrors.push(`${data[i].max_vol}${sr_no}_max_vol`);
    //   } else if (!data[i].max_vol) {
    //     tempErrors.push(`${data[i].max_vol}${sr_no}_max_vol`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].max_vol}${sr_no}_max_vol`);
    // }
    // try {
    //   if (!typeof parseFloat(data[i].vol_limit) || parseFloat(data[i].vol_limit) === 0) {
    //     tempErrors.push(`${data[i].vol_limit}${sr_no}_vol_limit`);
    //   } else if (!data[i].vol_limit) {
    //     tempErrors.push(`${data[i].vol_limit}${sr_no}_vol_limit`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].vol_limit}${sr_no}_vol_limit`);
    // }
    // try {
    //   if (!typeof parseFloat(data[i].contract_specs) || parseFloat(data[i].contract_specs) === 0) {
    //     tempErrors.push(`${data[i].contract_specs}${sr_no}_contract_specs`);
    //   } else if (!data[i].contract_specs) {
    //     tempErrors.push(`${data[i].contract_specs}${sr_no}_contract_specs`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].contract_specs}${sr_no}_contract_specs`);
    // }
    // try {
    //   if (!typeof parseInt(data[i].popular_product_rank) || parseInt(data[i].popular_product_rank) === 0) {
    //     tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
    //   } else if (!data[i].popular_product_rank) {
    //     tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].popular_product_rank}${sr_no}_popular_product_rank`);
    // }
    // try {
    //   if (data[i].isin.trim().length === 0) {
    //     tempErrors.push(`${data[i].isin}${sr_no}_isin`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].isin}${sr_no}_isin`);
    // }
    // try {
    //   if (data[i].ric_code.trim().length === 0) {
    //     tempErrors.push(`${data[i].ric_code}${sr_no}_ric_code`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].ric_code}${sr_no}_ric_code`);
    // }
    // try {
    //   if (data[i].product_name.trim().length === 0) {
    //     tempErrors.push(`${data[i].product_name}${sr_no}_product_name`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].product_name}${sr_no}_product_name`);
    // }
    // try {
    //   if (data[i].ticker.trim().length === 0) {
    //     tempErrors.push(`${data[i].ticker}${sr_no}_ticker`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].ticker}${sr_no}_ticker`);
    // }
    // try {
    //   if (data[i].ric.trim().length === 0) {
    //     tempErrors.push(`${data[i].ric}${sr_no}_ric`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].ric}${sr_no}_ric`);
    // }
    // try {
    //   if (data[i].exchange.trim().length === 0) {
    //     tempErrors.push(`${data[i].exchange}${sr_no}_exchange`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].exchange}${sr_no}_exchange`);
    // }
    // try {
    //   if (data[i].id.trim().length === 0) {
    //     tempErrors.push(`${data[i].exchange}${sr_no}_id`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].id}${sr_no}_id`);
    // }


  }
  console.log("duplicateAssetCombinationObject")
  console.log(duplicateAssetCombinationObject)
  return {
    tempErrors: tempErrors,
    duplicateSymbolObject: duplicateSymbolObject,
    duplicateAssetCombinationObject: duplicateAssetCombinationObject
  };
}
