import { Buffer } from "buffer";
import { JSEncrypt } from "jsencrypt";
import { GET_PUBLIC_KEY } from "./apiString";
import axios from "axios";
const encrypt = new JSEncrypt();

const payloadEncryptor = async (jsonData) => {
  try {
    if (!localStorage.getItem("pk")) {
      let res = await axios.get(GET_PUBLIC_KEY);
      const d = Buffer.from(res.data, "base64").toString("ascii");
      encrypt.setPublicKey(d);
      localStorage.setItem("pk", d);
    } else {
      let pk = localStorage.getItem("pk") || "";
      encrypt.setPublicKey(pk);
    }

    console.log("1", jsonData);
    console.log("2", encrypt.encrypt(jsonData));
    return encrypt.encrypt(jsonData);
  } catch (e) {
    console.log(e.message);
  }
};

export default payloadEncryptor;
