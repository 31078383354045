
import { CircularProgress } from '@mui/material';
import { Skeleton, Space, Table } from 'antd';

export default function TableSkeleton(props) {

  const { scrollHeight, resultType } = props;

  const renderedRows = [...Array(12)].map((e, i) => (
    {}
  ));


  function DataSkeleton() {
    return <div style={{ width: '100%' }}>
      <div className="skeleton"></div>
    </div>
  }


  const columns = [
    { title: "Serial", width: 100, render: (row) => <DataSkeleton /> },
    { title: "Asset Type", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Sub Asset", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Group", width: 200, render: (row) => <DataSkeleton /> },
    { title: "Symbol", width: 200, render: (row) => <DataSkeleton /> },
    { title: "Description", render: (row) => <DataSkeleton /> },
    { title: "Min Vol", width: 100, render: (row) => <DataSkeleton /> },
    { title: "Max Vol", width: 100, render: (row) => <DataSkeleton /> },
    { title: "Vol Limit", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Contract Specs", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Popular product rank", width: 150, render: (row) => <DataSkeleton /> },
    { title: "ISIN", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Ric Code", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Product Code", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Product Name", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Ticker", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Ric", width: 150, render: (row) => <DataSkeleton /> },
    { title: "Exchange", width: 150, render: (row) => <DataSkeleton /> },
    { title: "ID", width: 150, render: (row) => <DataSkeleton /> },



  ];


  return <Table
    dataSource={renderedRows}
    columns={columns}
    pagination={false}
    scroll={{
      y: scrollHeight
    }}
    title={() => <Space>
      <CircularProgress disableShrink size={16} />
      <span>{resultType === "imported" ? "Importing file..." : "Loading file from history...."}</span>
    </Space>} />
}



