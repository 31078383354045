export const config = {
  appId: "c1e894e8-9934-4c78-8de6-6dd7b7e40290",
  redirectUri: "https://mis.century.ae/",
  // redirectUri: "http://localhost:3000",

  scopes: [
    "user.read",
    "api://c1e894e8-9934-4c78-8de6-6dd7b7e40290/SuperSpecialScope",
  ],
  authority: "https://login.microsoftonline.com/centuryuae.onmicrosoft.com",
  postLogoutRedirectUri: "https://mis.century.ae",
};
