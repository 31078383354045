import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GlobalError } from "./globalError";

export default function SubAsset(props) {
    const { sub_asset, sr_no, group } = props;
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [elementId, setElementId] = useState(sub_asset + "" + sr_no + "_sub_asset");
    function CountryEquity() {
        GlobalError(sub_asset, setIsError, setErrMsg)
        if ((group.trim().toLowerCase()).includes("cryptocurrency")) {
            if (sub_asset != "" && sub_asset != undefined) {
                setErrMsg("For Cryptocurrency Group field must be empty")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("cash equity") || (group.trim().toLowerCase()).includes("us equity")) {
            if (sub_asset.trim().toLowerCase() != "usa") {
                setErrMsg("Sub Asset should be USA")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("denmark equity")) {
            if (sub_asset.trim().toLowerCase() != "denmark") {
                setErrMsg("Sub Asset should be Denmark")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("euro equity")) {
            if (sub_asset.trim().toLowerCase() != "europe") {
                setErrMsg("Sub Asset should be Europe")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("hongkong equity")) {
            if (sub_asset.trim().toLowerCase() != "hongkong") {
                setErrMsg("Sub Asset should be Hongkong")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("norway equity")) {
            if (sub_asset.trim().toLowerCase() != "norway") {
                setErrMsg("Sub Asset should be Norway")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("swedish equity")) {
            if (sub_asset.trim().toLowerCase() != "sweden") {
                setErrMsg("Sub Asset should be Sweden")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("switzerland equity")) {
            if (sub_asset.trim().toLowerCase() != "switzerland") {
                setErrMsg("Sub Asset should be Switzerland")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("uk equity")) {
            if (sub_asset.trim().toLowerCase() != "uk") {
                setErrMsg("Sub Asset should be UK")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("energy1")) {
            if (sub_asset.trim().toLowerCase() != "futures") {
                setErrMsg("Sub Asset should be Futures")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("fix metal")) {
            if (sub_asset.trim().toLowerCase() != "cash") {
                setErrMsg("Sub Asset should be Cash")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("forex minors")) {
            if (sub_asset.trim().toLowerCase() != "minor") {
                setErrMsg("Sub Asset should be Minor")
                setIsError(true);
            }
        }
        else if ((group.trim().toLowerCase()).includes("metals gold 1")) {
            if (sub_asset.trim().toLowerCase() != "cash") {
                setErrMsg("Sub Asset should be Cash")
                setIsError(true);
            }
        }
    }
    useEffect(() => {
        try {
            CountryEquity()
        } catch (e) {
            // setErrors(old => [...old, elementId]);
            setIsError(true);
        }
    }, []);

    return (
        <>
            {isError && (
                <Tooltip title={errMsg ? errMsg : "Sub Asset can't be empty"}>
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <Space>
                            <span style={{ color: "red" }}>{sub_asset}</span>
                            <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
                        </Space>
                    </div>
                </Tooltip>
            )}
            {!isError && (
                <div id={elementId} style={{ paddingLeft: 4 }}>
                    <span style={{ color: "black" }}>{sub_asset}</span>
                </div>
            )}
        </>
    );
}
