import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  default as PersonOutlineOutlined,
  default as PersonOutlineOutlinedIcon,
} from "@mui/icons-material/PersonOutlineOutlined";
import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import sidebarTheme from "../components/backofficeSidebarTheme";
import {
  ADD_MODULES,
  BASE_URL,
  CHECK_ALLOWED_USERS,
  GET_ENCRYPTED_EMAIL,
  GET_ROLE_BASE_TAB_LIST,
} from "../helper/apiString";
import FeeGroups from "./groups/feeGroups";
import ExecutionHistoryPage from "./execution_detail_page";
import OpenPositionHome from "./open_positions/openPositionHome";
import SMTP from "./smtp/index";
import Symbols from "./symbols/symbols";
import UserRoles from "./user_roles";
import UserRoleConext from "./user_roles/userRoleContext";
import Users from "./users";
import { JSEncrypt } from "jsencrypt";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  DropdownLink,
  Nav,
  NavIcon,
  SidebarData,
  SidebarLabel,
  SidebarLink,
  SidebarNav,
  SidebarWrap,
} from "./constants";
import CryptoJS from "crypto-js";
import HoldingCostDashboard from "./holding_cost/holdingCostDashboard";
import SymbolMasterUploader from "./symbol_master_uploader";
import payloadEncryptor from "../helper/payloadEncryptor";

export default function Home(props) {
  const encrypt = new JSEncrypt();
  const { window } = props;
  let { module } = useParams();
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [tabPanelValue, setTabPanelValue] = useState(module);
  const toggleButtonStyle = {
    sx: { textTransform: "none", color: context.headingColor },
  };
  const [url, setUrl] = useState();
  let [tabList, setTabList] = useState([
    // { label: "User Roles", value: 'User Roles' },
    // { label: "Users", value: 'Users' }
  ]);
  const userContext = useContext(UserRoleConext);
  const [modulePermissionData, setModulePermissionData] = useState({});

  const logout = () => {
    localStorage.clear();
    navigate("/");
    // window.location.reload();
  };
  function getEncryptedEmail() {
    axios.post(BASE_URL + "/" + "TU" + GET_ENCRYPTED_EMAIL).then((res) => {
      setUrl("https://centra.century.ae?em=" + res.data);
    }).catch(e => {
      console.log("ERROR: ", e.message)
    })
  }
  function closeSnackbar() {
    context.setSnackbarMsg(false);
  }
  function updateAllModule() {
    axios
      .post(ADD_MODULES)
      .then((res) => {
        console.log(res.data.length, " Modules Added");
      })
      .catch((err) => {
        console.log(err.message);
        logout();
      });
  }
  async function userAuth() {
    updateAllModule();
    // setTabList([
    //     { label: "User Roles", value: 'User Roles' },
    //     { label: "Users", value: 'Users' }])

    axios
      .post(BASE_URL + "/" + "TU" + GET_ROLE_BASE_TAB_LIST)
      .then((res) => {
        let allowedTabList = [];
        let modulePermissionObj = {};
        let mList = res.data.allowedModuleList;
        for (let i = 0; i < mList.length; ++i) {
          modulePermissionObj[Object.keys(mList[i])[0]] =
            mList[i][Object.keys(mList[i])];
          if (mList[i][Object.keys(mList[i])][0] == 1) {
            allowedTabList.push({
              label: Object.keys(mList[i])[0],
              value: Object.keys(mList[i])[0],
            });
          }
        }
        setTabList(allowedTabList);
        setModulePermissionData(modulePermissionObj);
        if (allowedTabList.length != 0 && module === undefined)
          navigate("/mis_home/" + allowedTabList[0].value);
      })
      .catch((err) => {
        console.log("Auth Error", err);
        logout();
      });
  }
  function handleIframeLoad() {
    setIframeLoaded(true);
    console.log("iframe loaded");
  }

  useEffect(() => {
    userAuth();
    getEncryptedEmail();
  }, []);
  useEffect(() => {
    if (module === "File Uploader Dashboard") {
      let customBoxHeight = document.getElementById("customId").style.height;
      setIframeHeight(customBoxHeight);

      //Add verify token API
    }
  }, [module]);
  // return <SideBar tabList={tabList} />;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        flexDirection: "row",
        overflow: "hidden",
      }}
    >
      {<SideBar tabList={tabList} />}

      <Divider orientation="vertical" sx={{ height: "100%" }} />

      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "scroll",
          boxSizing: "border-box",
        }}
        id={"customId"}
      >
        <Divider />
        {module === "Fee Groups" && <FeeGroups />}
        {module === "Symbols" && <Symbols />}
        {module === "User Roles" && <UserRoles />}
        {module === "Open Position" && <OpenPositionHome />}
        {module === "Users" && (
          <Users modulePermissionData={modulePermissionData["Users"]} />
        )}
        {module === "SMTP Setup" && (
          <SMTP modulePermissionData={modulePermissionData["SMTP Setup"]} />
        )}
        {module === "Holding Cost Rebate" && <ExecutionHistoryPage />}
        {module === "Symbol Master Uploader" && <SymbolMasterUploader />}
        {module === "Holding Cost Dashboard" && <HoldingCostDashboard />}
        {/* {module === "Dashboard File Uploader" && !iframeLoaded && <><LinearProgress sx={{ justifyContent: "center>" }} /><h1>Loading...</h1></>} */}
        {module === "File Uploader Dashboard" && (
          <div>
            {
              <iframe
                src="https://dashboard.century.ae/tu-file-uploader/home"
                width="100%"
                height="990"
                style={{ overflowX: "hidden", border: "none" }}
                loading="eager"
                onload={handleIframeLoad}
              ></iframe>
            }
          </div>
        )}
        {module === "Commission & Spread" && (
          <div>
            {
              <iframe
                src="https://dashboard.century.ae/admin"
                width="100%"
                height="990"
                style={{ overflowX: "hidden", border: "none" }}
                onload={handleIframeLoad}
              ></iframe>
            }
          </div>
        )}
        {console.log({ url })}
        {module === "Centra" && (
          <div>
            {
              <iframe
                src={url}
                width="100%"
                height="990"
                style={{ overflowX: "hidden", border: "none" }}
                onload={handleIframeLoad}
              ></iframe>
            }
          </div>
        )}
        <Snackbar
          open={context.snackbarMsg}
          autoHideDuration={6000}
          severity={context.severity}
          onClose={closeSnackbar}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert
            onClose={closeSnackbar}
            severity={context.severity}
            sx={{ width: "100%" }}
          >
            {context.snackbarMsg}
          </Alert>
        </Snackbar>

        {![
          "theming",
          "Holding Cost Rebate",
          "Commission & Spread",
          "Centra",
          "Holding Cost Dashboard",
          "File Uploader Dashboard",
          "Symbol Master Uploader",
          "automations",
          "Open Position",
          "Users",
          "registration",
          "Fee Groups",
          "Symbols",
          "User Roles",
          "approvalStages",
          "emailDesigner",
          "legalDocs",
          "SMTP Setup",
        ].includes(module) && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h1">404</Typography>

              <h3>Page you're looking for does not exist</h3>
            </Box>
          )}
      </Box>
    </Box>
  );

  function SideBar(props) {
    const { tabList } = props;

    ///This is the Side Bar check
    console.log(tabList);

    useEffect(() => { }, []);

    function onTabChange(e, tabValue) {
      if (context.somethingChanged) {
        if (
          window.confirm(
            "You have unsaved changes that you will lose if you continue. Do you still want to continue?"
          )
        ) {
          setTabPanelValue(tabValue);
          navigate("/mis_home/" + tabValue);
          context.setSomethingChanged(false);
        }
        return;
      }
      setTabPanelValue(tabValue);
      navigate("/mis_home/" + tabValue);
    }

    const sideBarStyleProps = {
      width: "220px",
      height: "100%",

      flexDirection: "column",
    };
    return (
      <Box sx={sideBarStyleProps}>
        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "80%" }}
            src={
              "https://d10t455z86w23i.cloudfront.net/public/uploads/settings/company_logo_2022__1697520422.png"
            }
          />
        </Box>
        <Divider />
        <ThemeProvider theme={sidebarTheme}>
          <Tabs
            orientation="vertical"
            value={tabPanelValue}
            onChange={onTabChange}
            aria-label="lab API tabs example"
          >
            {tabList.map((tab) => {
              return (
                <Tab
                  sx={{ alignItems: "flex-start" }}
                  label={tab.label}
                  value={tab.value}
                />
              );
            })}
          </Tabs>
        </ThemeProvider>
        <Divider />
        <AppBar notifications={notifications} />
      </Box>
    );
  }
  function AppBar(props) {
    const { notifications } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notificationAnchor, setNotificationAnchor] = useState(null);

    const isNotificationPopoverOpen = Boolean(notificationAnchor);

    function openNotificationPopover(e) {
      setNotificationAnchor(e.currentTarget);
    }

    function closeNotificationPopover() {
      setNotificationAnchor(null);
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <Box
        sx={{
          height: "56px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box pr={2} sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {/* <LanguageButton /> */}

          <Badge
            sx={{
              "& .MuiBadge-badge": {
                right: 0,
                top: 28,
                padding: "0 4px",
              },
            }}
            badgeContent={notificationsCount}
            color="primary"
          >
            <IconButton
              onClick={openNotificationPopover}
              size="small"
              sx={{ border: "1px solid gray" }}
            >
              <NotificationsNoneOutlinedIcon />
            </IconButton>
          </Badge>

          <NotificationPopover
            notifications={notifications}
            isOpen={isNotificationPopoverOpen}
            anchorEl={notificationAnchor}
            onClose={closeNotificationPopover}
          />

          <IconButton
            size="small"
            onClick={handleClick}
            sx={{ border: "1px solid gray" }}
          >
            <PersonOutlineOutlinedIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Box sx={{ width: 200 }} p={2}>
              <Box
                sx={{
                  width: "100%",
                  height: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AccountCircleIcon sx={{ fontSize: 100 }} />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant={"h6"}>
                  {localStorage.getItem("userType") == "MISA"
                    ? "Admin"
                    : localStorage.getItem("userType") == "MISB"
                      ? "Back Office User"
                      : ""}
                </Typography>
                <Button variant="outlined" onClick={logout}>
                  Logout
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    );
  }
  function SideBarOne(props) {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
      console.log("I m being clicked");
      setSidebar(!sidebar);
    };

    return (
      <>
        <IconContext.Provider value={{ color: "#fff" }}>
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
          </Nav>
          <SidebarNav sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
              </NavIcon>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
      </>
    );
  }

  function SubMenu(props) {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
      <>
        <SidebarLink to={props.path} onClick={props.subNav && showSubnav}>
          <div>
            {props.icon}
            <SidebarLabel>{props.title}</SidebarLabel>
          </div>
          <div>
            {props.subNav && subnav
              ? props.iconOpened
              : props.subNav
                ? props.iconClosed
                : null}
          </div>
        </SidebarLink>
        {subnav &&
          props.subNav.map((props, index) => {
            return (
              <DropdownLink to={props.path} key={index}>
                {props.icon}
                <SidebarLabel>{props.title}</SidebarLabel>
              </DropdownLink>
            );
          })}
      </>
    );
  }
}

function NotificationPopover(props) {
  const { isOpen, anchorEl, onClose, notifications } = props;
  const context = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <List disablePadding>
        {notifications.map((n) => {
          return (
            <>
              <ListItemButton
                onClick={(e) => {
                  if (n.module === "onboarding") {
                    navigate("/backoffice/users?email=" + n.data.email);
                  } else if (n.module === "fundings") {
                    navigate("/backoffice/fundings");
                  }
                }}
                sx={{ width: "400px" }}
              >
                <ListItemIcon>
                  {n.module === "onboarding" && <PersonOutlineOutlined />}
                </ListItemIcon>
                <ListItemText
                  primary={n.title}
                  secondary={
                    <>
                      <span>{n.content}</span>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        <span>
                          {moment(n.createdAt).format("DD/MM/yy hh:mm")}
                        </span>
                      </Box>
                    </>
                  }
                />
              </ListItemButton>
              <Divider />
            </>
          );
        })}
      </List>
    </Popover>
  );
}
