// export const BASE_URL = "http://localhost:5001";
export const BASE_URL = "https://mis.century.ae/api";

export const ADD_MODULES = BASE_URL + "/users/addModules";

export const CHECK_ALLOWED_USERS = BASE_URL + "/users/checkAllowedUsers";

export const GET_PUBLIC_KEY = BASE_URL + "/users/getPublicKey";

export const GET_GROUPS_MASTER = BASE_URL + "/users/getGroupsMaster";

export const GET_SYMBOLS_MASTER = BASE_URL + "/users/getSymbolsMaster";

export const IMPORT_SECURITIES_URL = BASE_URL + "/users/importSecurities";

export const GET_GROUP_SYMBOL_CONFIGURATION =
  BASE_URL + "/users/getGroupSymbolConfiguration";

export const GET_GROUP_COMMISSION_CONFIGURATION =
  BASE_URL + "/users/getGroupCommissionConfiguration";

export const UPLOAD_SWAP_MASTER = BASE_URL + "/users/uploadSwapMaster";

export const UPLOAD_HOLDING_RATE_MASTER =
  BASE_URL + "/users/uploadHoldingRateMaster";

export const GET_FORMATTED_FILE_HOLDING_COST =
  BASE_URL + "/users/getFormattedFileHoldingCost";

export const REMOVE_FORMATTED_FILE = BASE_URL + "/users/removeFormattedFile";

export const FETCH_FORMATTED_FILE_MASTER =
  BASE_URL + "/users/fetchFormattedFileMaster";

export const READ_HOLDING_COST_UPLOADS_DIR =
  BASE_URL + "/users/readHoldingCostUploadsDir";

export const FETCH_FORMATTED_FILE_MASTER_BY_NAME =
  BASE_URL + "/users/fetchFormattedFileMasterByName";

export const UPDATE_SMTP = BASE_URL + "/users/updateSmtpDetails";

export const GET_SMTP = BASE_URL + "/users/getSmtpDetails";

export const ADD_USER_ROLE = BASE_URL + "/users/addUserRole";

export const GET_USER_ROLES = BASE_URL + "/users/getUserRoles";

export const GET_USER_MODULES = BASE_URL + "/users/getUserModules";

export const DELETE_USER_ROLE = BASE_URL + "/users/deleteUserRole";

export const UPDATE_USER_ROLE_FIELD_PERMISSION =
  BASE_URL + "/users/updateUserRoleFieldPermission";

export const UPDATE_USER_ROLE_MODULE_PERMISSION =
  BASE_URL + "/users/updateUserRoleModulePermission";

export const ADD_USER_ROLE_MODULE_PERMISSION =
  BASE_URL + "/users/addUserRoleModulePermission";

export const CREATE_USER = BASE_URL + "/users/createUser";

export const GET_USER = BASE_URL + "/users/getUsers";

export const DELETE_USER = BASE_URL + "/users/deleteUser";

export const UPDATE_USER_INFO = BASE_URL + "/users/updateUserInfo";

export const GET_ROLE_BASE_TAB_LIST = "/users/getRoleBaseTabList";

export const GET_ACCESS_TOKEN = BASE_URL + "/users/getAccessToken";

export const GET_OPEN_POSITION_MASTER = "/users/getOpenPositionMaster";

export const GET_TREE_MAP_DATA = "/users/getTreeMapData";

export const GET_ALL_SWAP_CHANGE_MASTER = "/users/getAllSwapChangeMaster";

export const GET_HOLDING_COST = "/users/getHoldingCostMaster";

export const GET_HOLDING_COST_MASTER_DATA_INDICATIONS = "/users/getHoldingCostMasterDataIndications";

export const GET_HOLDING_REBATE_EXECUTION_LOGS = "/users/getHoldingRebateExecutionLogs";

export const GET_ENCRYPTED_EMAIL = "/users/getEncryptedEmail";

export const FETCH_SYMBOL_MASTER = BASE_URL + "/users/fetchSymbolMaster";

export const GET_UPLOADED_SYMBOL_MASTERS = BASE_URL + "/users/getUploadedSymbolMasters";

export const UPLOAD_SYMBOL_MASTER = BASE_URL + "/users/uploadSymbolMaster";

export const REMOVE_SYMBOL_MASTER = BASE_URL + "/users/removeSymbolMaster";

export const GET_LAST_UPLOADED_SYMBOL_MASTER = BASE_URL + "/users/getLastUploadedSymbolMaster";
