import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
const CustomDialog = (props) => {
  const { message, customAction, open, onClose } = props;
  function onDelete() {
    customAction();
    onClose();
  }
  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <Box mt={2}>
            <Typography>{message}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton onClick={onDelete}>Delete</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CustomDialog;
