import { Column } from "@ant-design/plots";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tabs,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Divider, Tooltip } from "antd";
import axios from "axios";
import { forEach, groupBy } from "lodash-es";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import DateRangeTimePicker from "../../components/dateRangeTimePicker";
import { BASE_URL, GET_HOLDING_COST, GET_HOLDING_COST_MASTER_DATA_INDICATIONS } from "../../helper/apiString";
import { GridFilterModel } from '@mui/x-data-grid'
import { infoMapHoldingCost, namingMapHoldingCost } from "../constants";
import StatCard from "../global_component/statCard-component";
import UserRoleConext from "../user_roles/userRoleContext";
import payloadEncryptor from "../../helper/payloadEncryptor";
import encryptStringInChunks from "../../helper/encryptStringInChunks";
import moment from "moment";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffd700",//theme.palette.common.black,
    color: theme.palette.common.black,
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const HoldingCostDashboard = () => {
  const statCardGridSize = { xl: 2, lg: 3, md: 4, sm: 6, xs: 12 };
  const [holdingCost, setHoldingCost] = useState();
  const [clientList, setClientList] = useState();
  const [positionIdList, setPositionIdList] = useState();
  const [symbolList, setSymbolList] = useState();
  const [chargedToClient, setChargedToClient] = useState();
  const [receivedByClient, setreceivedByClient] = useState();
  const [chargedToClientMaster, setChargedToClientMaster] = useState();
  const [receivedByClientMaster, setreceivedByClientMaster] = useState();
  const [errorRecords, setErrorRecords] = useState();
  const [dataGridElement, setDataGridElement] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [reloadData, setReloadData] = useState(true);
  const [maskingState, setMaskingState] = useState(true);
  const [client, setClient] = useState("All");
  const [symbol, setSymbol] = useState("All");
  const [positionId, setPositionId] = useState("All");
  const [tabs, setTabs] = useState("Total Records");
  // const [tabs == "Client Charged", settabs == "Client Charged"] = useState(false);
  // const [tabs == "Client Received", settabs == "Client Received"] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [items, setItems] = useState([]);
  // const isMatch = useMediaQuery(theme.breakpoints.down('xs'))
  const filterValues = useRef({
    client: "",
    symbol: "",
    startDate: "",
    endDate: "",
    positionId: "",
  });
  const userContext = useContext(UserRoleConext);
  const navigate = useNavigate();
  const columns = [
    {
      field: "createdAt",
      renderCell: (params) => {
        console.log(params.row.createdAt)
        return moment(params.row.createdAt).utc().format("DD-MM-YYYY");
      },
      renderHeader: (params) => <strong> {"Date"} </strong>,
      flex: 1,
      minWidth: 50,
    },
    {
      field: "loginId",
      renderCell: (params) => {
        return params.row.client;
      },
      renderHeader: (params) => <strong> {"Client"} </strong>,
      flex: 1,
      minWidth: 50,
    },
    {
      field: "feeGroup",
      renderHeader: (params) => <strong> {"Fee Group"} </strong>,
      renderCell: (params) => {
        return params.row.feeGroup;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "symbol",
      renderHeader: (params) => <strong> {"Symbol"} </strong>,
      renderCell: (params) => {
        return params.row.symbol;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "positionId",
      renderHeader: (params) => <strong> {"Position ID"} </strong>,
      renderCell: (params) => {
        return params.row.positionId;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "openTime",
      renderHeader: (params) => <strong> {"Open Time"} </strong>,
      renderCell: (params) => {
        return params.row.openTime;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "orderType",
      renderHeader: (params) => <strong> {"Order Type"} </strong>,
      renderCell: (params) => {
        return params.row.orderType;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rateProfit",
      renderHeader: (params) => <strong> {"Conversion Rate"} </strong>,
      renderCell: (params) => {
        return params.row.rateProfit;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rateMargin",
      renderHeader: (params) => <strong> {"Margin Rate"} </strong>,
      renderCell: (params) => {
        return (params.row.rateMargin) + " %";
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "volume",
      renderHeader: (params) => <strong> {"Volume"} </strong>,
      renderCell: (params) => {
        console.log(params.row)
        return (params.row.volume / 1000);
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "baseHoldingRate",
      renderHeader: (params) => <strong> {"Base Rate"} </strong>,
      renderCell: (params) => {
        return (params.row.baseHoldingRate * 100).toFixed(3) + " %";
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "markupHoldingRate",
      renderHeader: (params) => <strong> {"Markup Rate"} </strong>,
      renderCell: (params) => {
        return (params.row.markupHoldingRate * 100).toFixed(3) + " %";
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "finalHoldingRate",
      renderHeader: (params) => <strong> {"Final Rate"} </strong>,
      renderCell: (params) => {
        return (parseFloat(params.row.finalHoldingRate) * 100).toFixed(3) + " %";
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "markRateColor",
      renderHeader: (params) => <strong> {"Color"} </strong>,
      renderCell: (params) => {
        return params.row.markRateColor;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "storage",
      renderHeader: (params) => <strong> {"Total Swap"} </strong>,
      renderCell: (params) => {
        return params.row.storage;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dailySwapChange",
      renderHeader: (params) => <strong> {"Changed Swap"} </strong>,
      renderCell: (params) => {
        return params.row.dailySwapChange;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lpCost",
      renderHeader: (params) => <strong> {"LP Cost"} </strong>,
      renderCell: (params) => {
        return params.row.lpCost;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rebatableSwap",
      renderHeader: (params) => <strong> {"SWAP Rebate"} </strong>,
      renderCell: (params) => {
        return params.row.rebatableSwap;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "platform",
      renderHeader: (params) => <strong> {"Platform"} </strong>,
      renderCell: (params) => {
        return params.row.platform;
      },
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
  ];
  const togglePlatformType = () => {
    resetFilter();
    setReloadData(true);
    if (userContext.platformType == "TU") {
      userContext.setPlatformType("CFC");
    } else {
      userContext.setPlatformType("TU");
    }
  };
  function resetFilter() {
    filterValues.current = {
      client: "",
      symbol: "",
      startDate: "",
      endDate: "",
      positionId: ""
    };
    setDateRange([]);
    setClient("All");
    setSymbol("All");
    setPositionId("All");
  }
  async function getHoldingCost() {
    setIsLoading(true);
    // let payload = await payloadEncryptor(filterValues.current);
    let payload = await encryptStringInChunks(filterValues.current);
    axios
      .post(BASE_URL + "/" + userContext.platformType + GET_HOLDING_COST, {
        payload,
      })
      .then((result) => {
        setHoldingCost(result.data);
        if (result.data[0]["swapChangeMasterData"].length != 0) {
          setClientList(result.data[0].uniqueLists[0].totalClients);
          setSymbolList(result.data[0].uniqueLists[0].totalSymbols);
          setPositionIdList(result.data[0].uniqueLists[0].totalPosition);
          setChargedToClient(result.data[0].swapChargedToClient);
          setreceivedByClient(result.data[0].swapReceivedByClient);
          setDataGridElement(result.data[0]["swapChangeMasterData"]);
        } else {
          setClientList([]);
          setSymbolList([]);
          setDataGridElement([]);
          setPositionIdList([]);
          setChargedToClient([]);
          setreceivedByClient([]);
        }
        axios
          .post(BASE_URL + "/" + userContext.platformType + GET_HOLDING_COST_MASTER_DATA_INDICATIONS, {
            payload,
          }).then((result) => {
            setChargedToClientMaster(result.data[0].swapChangeDataClientCharged);
            setreceivedByClientMaster(result.data[0].swapChangeDataClientReceived);
            setErrorRecords(result.data[0].swapChangeDataError);
            setIsLoading(false);
          }).catch((e) => {
            if (e.response.status === 401) navigate("/");
            setIsLoading(true);
          })
      })
      .catch((e) => {
        if (e.response.status === 401) navigate("/");
        setIsLoading(true);
        console.log(e.message);
      });
  }
  const tabCalled = (param) => {
    setTabs(param)
  }
  const callGetOpenPosition = () => {
    setReloadData(!reloadData);
    getHoldingCost();
  };
  const callResetFilter = () => {
    setReloadData(!reloadData);
    resetFilter();
  };
  const handleValueChange = (e) => {
    let old = filterValues.current;
    old = filterValues.current;
    old[e.target.name] = e.target.value;
    filterValues.current = old;
    if (e.target.name == "client") {
      setClient(filterValues.current.client);
    }
    if (e.target.name == "symbol") {
      setSymbol(filterValues.current.symbol);
    }
    if (e.target.name == "positionId") {
      setPositionId(filterValues.current.positionId);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("adToken")) navigate("/");
    getHoldingCost();
    setTabs("Total Records");
  }, [reloadData, userContext.platformType]);
  return (<>

    {!isLoading ? (<>
      {
        <Box>
          {/* {holdingCost &&
            <Dialog open={dialogOpen}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "100%",
                    maxHeight: "900px"
                  },
                },
              }}>
              <DialogTitle>{"Error Data"}</DialogTitle>
              <DialogContent>
                <DataGridElement columns={columns} pageSize={pageSize} setPageSize={setPageSize} holdingCost={holdingCost} calledFor={""} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
          } */}
          <Grid
            container
            spacing={5}
            p={3}
            mb={3}
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: "#ffd700" }}
          >
            <Grid item lg={9} xl={9} md={9} sm={12} xs={12}>
              <Typography style={{ fontWeight: 700, fontSize: 24 }}>
                Holding Cost Dashboard
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Typography style={{ fontWeight: 700 }}>CENTURY</Typography>
              <Tooltip
                title={!reloadData && holdingCost ? "Data is Loading" : ""}
              >
                <span style={{ padding: "5px" }}>
                  <AntSwitch
                    disabled={!isLoading ? false : true}
                    checked={userContext.platformType == "TU" ? true : false}
                    onChange={(checked) => {
                      togglePlatformType();
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </span>
              </Tooltip>
              <Typography style={{ fontWeight: 700 }}>TU</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ backgroundColor: "#fafafa" }}>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Paper>
                <Item>
                  <Box
                    pt={2}
                    pb={2}
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          size="small"
                          value={positionId}
                          onChange={(e, option) => {
                            handleValueChange({
                              target: { name: "positionId", value: option },
                            });
                          }}
                          options={["All"].concat(positionIdList)}
                          renderInput={(params) => (
                            <TextField {...params} label="Position ID" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          size="small"
                          value={client}
                          onChange={(e, option) => {
                            handleValueChange({
                              target: { name: "client", value: option },
                            });
                          }}
                          options={["All"].concat(clientList)}
                          renderInput={(params) => (
                            <TextField {...params} label="Client" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          size="small"
                          value={symbol}
                          onChange={(e, option) => {
                            handleValueChange({
                              target: { name: "symbol", value: option },
                            });
                          }}
                          options={["All"].concat(symbolList)}
                          renderInput={(params) => (
                            <TextField {...params} label="Symbol" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                      <DateRangeTimePicker
                        state={dateRange}
                        setState={setDateRange}
                        filterValues={filterValues}
                      />
                    </Grid>
                    <Grid item lg={1} xl={1} md={3} sm={6} xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{ backgroundColor: "black" }}
                        onClick={callGetOpenPosition}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item lg={1} xl={1} md={2} sm={6} xs={12}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={callResetFilter}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Box>
                </Item>
              </Paper>
            </Grid>
          </Grid>
          <Divider />
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} md={12} sx={{ display: "flex", flexDirection: { xs: "column", md: "row", lg: "row" }, }}>

                <Grid item xs={12} sm={12} lg={6} md={6}>
                  {holdingCost[0].statistics[0] && !isLoading && (
                    <Grid
                      container
                      spacing={2}
                      p={1}
                      mt={5}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {Object.keys(holdingCost[0].statistics[0]).map(
                        (key, index) => {
                          if (key != "totalRecords" && key != "totalSwap" && key != "totalMarkup" && key != "totalLpSwap")
                            return (
                              <Grid item xs={12} sm={12} lg={4} md={4}>
                                <StatCard
                                  value={Math.abs(
                                    holdingCost[0].statistics[0][key]
                                  ).toLocaleString()}
                                  heading={namingMapHoldingCost[key]}
                                  infoKey={infoMapHoldingCost[key]}
                                />
                              </Grid>
                            );
                        }
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                  <Grid container spacing={2} p={1}
                  >
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <Typography style={{ fontWeight: 700, fontSize: 20 }}>Holding Cost Charged
                        <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </span>
                      </Typography>
                      <ChargedReceivedStats chargedToClient={chargedToClient} receivedByClient={receivedByClient} calledBy={"toClient"} maskingState={maskingState} />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={6}>
                      <Typography style={{ fontWeight: 700, fontSize: 20 }}>Holding Cost Received
                        <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </span>
                      </Typography>

                      <ChargedReceivedStats chargedToClient={chargedToClient} receivedByClient={receivedByClient} calledBy={"byClient"} maskingState={maskingState} />
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Divider />
            <p>
              {/* <Grid
                  container
                  spacing={2}
                  p={2}
                  sx={{ backgroundColor: "white" }}
                >
                  {holdingCost[0].barCharData.length != 0 ? (
                    <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          "& > :not(style)": {
                            width: "100%",
                            minHeight: "500px",
                            padding: "0 5%",
                          },
                        }}
                      >
                        <BarChartCompnent
                          sx={{ height: "250px !important" }}
                          swapData={holdingCost[0].barCharData}
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      lg={6}
                      xl={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{
                        fontWeight: "bold",
                        color: "#ffd700",
                        fontSize: "22px",
                      }}
                    >
                      Data Not Found
                    </Grid>
                  )}
                  <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          width: "100%",
                          minHeight: "500px",
                          padding: "0 5%",
                        },
                      }}
                    >
                      <Paper sx={{ height: "300px" }}>
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h2>
                            {value == 0 ? "Top Clients " : "Top Symbols"}
                            <InfoPopover
                              content={
                                value == 0 ? "Top Clients " : "Top Symbols"
                              }
                            />
                          </h2>
                          {holdingCost && (
                            <span style={{ marginLeft: "30%" }}>
                              <CSVLink
                                filename="Top Users - Swap Count"
                                data={
                                  value == 0
                                    ? holdingCost[0].topClientsGeneratingSwap ||
                                    []
                                    : holdingCost[0].topSymbolsGeneratingSwap ||
                                    []
                                }
                              >
                                <SystemUpdateAltIcon sx={{ color: "black" }} />
                              </CSVLink>
                            </span>
                          )}
                        </span>
                        <Tabs
                          value={value}
                          onChange={(e, value) => {
                            setValue(value);
                          }}
                        >
                          <Tab style={{ width: "33%" }} label="By Client" />
                          <Tab style={{ width: "33%" }} label="By Symbol" />
                        </Tabs>
                        {value === 0 && (
                          <div
                            style={{
                              overflow: "auto",
                              height: "350px",
                            }}
                          >
                            <Table
                              stickyHeader
                              style={{
                                tableLayout: "fixed",
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Client</StyledTableCell>
                                  <StyledTableCell>Swap</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {holdingCost &&
                                  Object.keys(
                                    holdingCost[0].topClientsGeneratingSwap
                                  ).map((x) => {
                                    return (
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          {
                                            holdingCost[0]
                                              .topClientsGeneratingSwap[x]
                                              .client
                                          }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {
                                            holdingCost[0]
                                              .topClientsGeneratingSwap[x]
                                              .clientSwap
                                          }
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </div>
                        )}
                        {value === 1 && (
                          <div
                            style={{
                              overflow: "auto",
                              height: "350px",
                            }}
                          >
                            <Table
                              stickyHeader
                              style={{
                                tableLayout: "fixed",
                              }}
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Symbol</StyledTableCell>
                                  <StyledTableCell>Swap</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {holdingCost &&
                                  Object.keys(
                                    holdingCost[0].topSymbolsGeneratingSwap
                                  ).map((x) => {
                                    return (
                                      <StyledTableRow>
                                        <StyledTableCell>
                                          {
                                            holdingCost[0]
                                              .topSymbolsGeneratingSwap[x]
                                              .symbol
                                          }
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          {
                                            holdingCost[0]
                                              .topSymbolsGeneratingSwap[x]
                                              .symbolSwap
                                          }
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </div>
                        )}
                      </Paper>
                    </Box>
                  </Grid>
                </Grid> */}
            </p>
            <Grid
              container
              spacing={2}
              p={2}
              sx={{ backgroundColor: "white" }}
            >
              <Grid item lg={12}>
                {holdingCost && (
                  <Box sx={{ height: 600, width: "100%" }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: tabs == "Total Records" ? "#ffd700" : "black",
                          color: tabs == "Total Records" ? "black" : "white",
                          height: "32px",
                          padding: "10px",
                          margin: "10px",

                        }}
                        onClick={() => {
                          setDataGridElement(holdingCost[0]["swapChangeMasterData"])
                          tabCalled("Total Records")
                        }}
                      >
                        {"Total Records"}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: tabs == "Error Records" ? "#ffd700" : "black",
                          color: tabs == "Error Records" ? "black" : "white",
                          height: "32px",
                          padding: "10px",
                          margin: "10px",

                        }}
                        onClick={() => {
                          setDataGridElement(errorRecords)
                          tabCalled("Error Records")
                        }}
                      >
                        {"Error Records"}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: tabs == "Client Charged" ? "#ffd700" : "black",
                          color: tabs == "Client Charged" ? "black" : "white",
                          height: "32px",
                          padding: "10px",
                          margin: "10px",

                        }}
                        onClick={() => {
                          setDataGridElement(chargedToClientMaster)
                          tabCalled("Client Charged")
                        }}
                      >
                        {"Client Charged"}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: tabs == "Client Received" ? "#ffd700" : "black",
                          color: tabs == "Client Received" ? "black" : "white",
                          height: "32px",
                          padding: "10px",
                          margin: "10px",

                        }}
                        onClick={() => {
                          setDataGridElement(receivedByClientMaster)
                          tabCalled("Client Received")
                        }}
                      >
                        {"Client Received"}
                      </Button>
                    </Box>
                    {tabs != undefined && <DataGridElement columns={columns} pageSize={pageSize} items={items} setPageSize={setPageSize} holdingCost={dataGridElement} />}
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        </Box >
      }
    </>) : (
      <>
        <LinearProgress sx={{ justifyContent: "center>" }} />
        <h1>Loading...</h1>
      </>
    )}
  </>)
};
function ChargedReceivedStats(props) {
  const { chargedToClient, receivedByClient, calledBy, maskingState } = props;
  let data = calledBy == "toClient" ? chargedToClient : receivedByClient;
  return <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 3 }}>
    <Item
      style={{
        backgroundColor: "white",
        fontWeight: "bold",
        display: "flex",
        flexFlow: "column",
        p: 2
      }}
    >
      <Table aria-label="simple table" sx={{ height: "126px" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">{calledBy == "toClient" ? "To Client" : "By Client"}</StyledTableCell >
            <StyledTableCell align="center">{calledBy == "toClient" ? "To Century" : "By Century"}</StyledTableCell >
            <StyledTableCell align="center">NetIncome</StyledTableCell >
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow
              key={row.dailySwapChange}
            >
              <StyledTableCell align="center" >{maskingState ? "*****" : row.dailySwapChange.toLocaleString(2) + " USD"}</StyledTableCell >
              <StyledTableCell align="center">{maskingState ? "*****" : row.lpCost.toLocaleString(2) + " USD"}</StyledTableCell >
              <StyledTableCell align="center">{maskingState ? "*****" : row.rebatableSwap.toLocaleString(2) + " USD"}</StyledTableCell >
            </StyledTableRow >
          ))}
        </TableBody>
      </Table>
    </Item>
  </Paper>

}
function DataGridElement(props) {
  const { columns, pageSize, setPageSize, holdingCost, items } = props;
  console.log(items)
  return <div style={{ height: 600 }}>
    <DataGrid
      initialState={{
        columns: {
          columnVisibilityModel: {
            markRateColor: false,
          },
        },
      }}
      getRowClassName={(params) => {
        return params.row.baseHoldingRate -
          params.row.finalHoldingRate <
          0
          ? "highlight"
          : "correctData";
      }}
      sx={{
        "& .highlight": {
          bgcolor: "#a31723",
          color: "white",
          fontWeight: "bold",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "grey",
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-cell:hover": {
          // color: '#ffd700',
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell:hover": {
          // color: '#ffd700',
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar":
        {
          width: "0.7em",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
        {
          background: "#ffffff",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
        {
          backgroundColor: "black",
          borderRadius: 2,
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
        {
          background: "#ffd700",
        },
      }}
      columns={columns}
      getRowId={(row) => row._id}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pageSize={pageSize}
      components={{
        Toolbar: GridToolbar,
      }}
      onPageSizeChange={(newPageSize) =>
        setPageSize(newPageSize)
      }
      rows={holdingCost}
      experimentalFeatures={{ newEditingApi: true }}
    />
  </div>
}
function BarChartCompnent(props) {
  const [data, setData] = useState(props.swapData);

  useEffect(() => { }, []);

  const annotations = [];
  forEach(groupBy(data, "createdDate"), (values, k) => {
    const value = values.reduce((a, b) => a + b.value, 0);
    annotations.push({
      type: "text",
      data: [k, value],
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      xField: "createdDate",
      yField: "value",
      style: {
        text: `${value}`,
        textBaseline: "bottom",
        position: "top",
        textAlign: "center",
      },
      tooltip: false,
    });
  });

  const config = {
    data,
    xField: "createdDate",
    yField: "value",
    stack: true,
    colorField: "type",
    label: {
      text: "value",
      textBaseline: "bottom",
      position: "inside",
    },
    annotations,
  };
  return <Column {...config} />;
}

function NoDataElement() {
  return (
    <>
      <br />
      <br />
      <br />
      <h2 style={{ color: "goldenrod" }}>No Data Available</h2>
    </>
  );
}
function InfoPopover(props) {
  const { content, color = "black" } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <InfoOutlinedIcon size="small" sx={{ color }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </>
  );
}

export default HoldingCostDashboard;
