import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GlobalError } from "./globalError";

export default function AssetType(props) {
    const { asset_type, sr_no, group, duplicateAssetCombinationObject } = props;
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [elementId, setElementId] = useState(asset_type + "" + sr_no + "_asset_type");

    useEffect(() => {
        try {
            GlobalError(asset_type, setIsError, setErrMsg)
            if (duplicateAssetCombinationObject[sr_no - 1] == true) {
                setIsError(true);
                setErrMsg("Duplicate asset type combination");
            }
            if (asset_type.trim().length === 0) {
                setIsError(true);
            }

            else if ((group.toLowerCase()).includes("equity")) {
                if (asset_type.toLowerCase() != "shares") {
                    setErrMsg("Asset Type should be shares")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("forex m") || (group.toLowerCase()).includes("fx")) {
                if (asset_type.toLowerCase() != "currencies") {
                    setErrMsg("Asset Type should be Currencies")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("cryptocurrency")) {
                if (asset_type.toLowerCase() != "crypto") {
                    setErrMsg("Asset Type should be Crypto")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("forex forward")) {
                if (asset_type.toLowerCase() != "currency forward") {
                    setErrMsg("Asset Type should be Currency Forward")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("currency index")) {
                if (asset_type.toLowerCase() != "currency indices") {
                    setErrMsg("Asset Type should be Currency Indices")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("cfd index")) {
                if (asset_type.toLowerCase() != "indices") {
                    setErrMsg("Asset Type should be Indices")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("treasuries")) {
                if (asset_type.toLowerCase() != "treasuries") {
                    setErrMsg("Asset Type should be Treasuries")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("index")) {
                if (asset_type.toLowerCase() != "indices") {
                    setErrMsg("Asset Type should be Indices")
                    setIsError(true);
                }
            }
            else if ((group.toLowerCase()).includes("metals") || (group.toLowerCase()).includes("agri") || (group.toLowerCase()).includes("energy")) {
                if (asset_type.toLowerCase() != "commodities") {
                    setErrMsg("Asset Type should be Commodities")
                    setIsError(true);
                }
            }
        } catch (e) {
            setIsError(true);
        }
    }, []);

    return (
        <>
            {isError && (
                <Tooltip title={errMsg ? errMsg : "Asset Type can't be empty"}>
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <Space>
                            <span style={{ color: "red" }}>{asset_type}</span>
                            <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
                        </Space>
                    </div>
                </Tooltip >
            )
            }
            {
                !isError && (
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <span style={{ color: "black" }}>{asset_type}</span>
                    </div>
                )
            }
        </>
    );
}
