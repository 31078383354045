export const CH = {
  signIn: "Sign in",
  signingIn: "Signing in...",

  superAdminLogin: "Super Admin Login",

  createAccount: "Create Account",
  username: "Username",

  email: "Email",
  password: "Password",
  showPassword: "Show Password",
  forgotPassword: "Forgot Password?",
  wrongCreds: "Wrong Email/Password",
  hello: "Hello",
  registeredEmail: "Registered Email",
  registeredPhone: "Registered Phone",
  onboardingAppSubmittedMsg: "Your application has been submitted for approval",
  dashboard: "仪表盘",
  profile: "轮廓",
  wallet: "钱包",
  trading: "贸易",
  settings: "设置",
  walletBalance: "钱包余额",
  credit: "信用",
  tickets: "Tickets",

  transactions: "交易",
  allTransactions: "所有交易",
  processing: "加工",
  cancel: "Cancel",
  cancelled: "Cancelled",
  approve: "Approve",
  reject: "Reject",
  submitForApproval: "Submit for approval",
  applicationForwarded: "Application forwarded",
  assign: "Assign",
  assignTo: "Assign to",
  assigned: "Assigned",
  assignedTo: "Assigned to",
  pageNotFoundMsg: "Page you're looking for does not exist",
  somethingWentWrongMsg: "Something went wrong.",
  group: "Group",
  groupName: "Group name",
  price: "Price",
  lotSize: "Lot size",
  initiated: "Initiated",
  initiatedOn: "Initiated on",
  dividendSplit: "Dividend Split",
  split: "Split",
  wait: "Wait",
  automations: "Automations",
  userRoles: "User Roles",
  approvalStages: "Approval Stages",
  feeGroups: "Fee Groups",
  securities: "Securities",
  regFields: "Registration Fields",
  label: "Label",
  enable: "Enable",
  enabled: "Enabled",
  required: "Required",
  conditional: "Conditional",
  section: "Section",
  sections: "Sections",
  aboutYou: "About You",
  accountSetup: "Account Setup",
  personalDetails: "Personal Details",
  taxInfo: "Tax Information",
  employmentDetails: "Employement Details",
  tradingExp: "Trading Experience",
  declaration: "Declaration",
  addNewSection: "Add New Section",
  addNewField: "Add New Field",
  confirmDelete: "Are you sure you want to delete this item?",

  approved: "Approved",
  rejected: "Rejected",
  txnRef: "Transaction Reference",
  txnRefPlaceholder: "Enter transaction reference",
  amountDepositPlaceholder: "Enter amount to deposit",
  amountWithdrawPlaceholder: "Enter amount to withdraw",
  selectAccount: "Select account",
  bankStatement: "Bank Statement",
  bankTxnRef: "Bank Txn Reference",
  bankTransfer: "Bank Transfer",
  creditDebitCard: "Credit / Debit Card",
  depositMethod: "Deposit Method",
  depositMoney: "Deposit Money",
  submitRequest: "Submit Request",
  viewHistory: "View History",
  openPositions: "Open Positions",
  environment: "Environment",
  currency: "Currency",
  balance: "Balance",
  leverage: "Leverage",
  equity: "Equity",
  margin: "Margin",
  marginLevel: "Margin Level",
  freeMargin: "Free Margin",
  demo: "Demo",
  live: "Live",
  noDataAvailable: "No Data Available",
  noRows: "No Rows",
  execute: "Execute",
  executed: "Executed",
  clients: "Clients",
  notification: "Notifications",
  language: "Language",
  changeLanguage: "Change language",
  rowsPerPage: "Rows per page",

  next: "Next",
  users: "Users",
  fundings: "Fundings",
  dividends: "Dividends",
  finances: "Finances",
  creditRequests: "Credit Requests",
  logout: "Logout",
  deposit: "订金",
  withdraw: "提取",
  deposits: "存款",
  withdrawals: "取款",

  accountType: "Account Type",
  accountId: "Account Id",
  phone: "Phone",
  status: "Status",
  regDate: "Registration Date",
  history: "History",
  action: "Action(s)",
  view: "View",
  viewDetails: "View Details",
  date: "Date",
  amount: "Amount",
  account: "Account",
  name: "Name",
  type: "Type",
};
