import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Save from "@mui/icons-material/Save";
import Collapse from "@mui/material/Collapse";
import { message } from "antd";
import axios from "axios";
import moment from "moment";
import {
  ADD_USER_ROLE,
  ADD_USER_ROLE_MODULE_PERMISSION,
  DELETE_USER_ROLE,
  GET_USER_MODULES,
  GET_USER_ROLES,
  UPDATE_USER_ROLE_FIELD_PERMISSION,
  UPDATE_USER_ROLE_MODULE_PERMISSION,
} from "../../helper/apiString";
import CustomDialog from "../global_component/customDialog";
import ModuleAccessComponent from "./moduleAccessComponent";
import userRoleConext from "./userRoleContext";
import payloadEncryptor from "../../helper/payloadEncryptor";
import encryptStringInChunks from "../../helper/encryptStringInChunks";

export default function UserRoles(props) {
  const columnHeads = ["Role Name", "Created", "Updated"];
  const [userRoles, setUserRoles] = useState([]);
  const [userModules, setUserModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserRoleListChanged, setIsUserRoleListChanged] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [saveChangeData, setSaveChangeData] = useState();
  const [createRoleDialogOpen, setCreateRoleDialogOpen] = useState(false);
  const newRoleValues = useRef({ roleName: "", desc: "" });
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  let context = useContext(userRoleConext);

  function fetchUserRoles() {
    setIsLoading(true);
    axios
      .get(GET_USER_ROLES)
      .then((res) => {
        setUserRoles(res.data);

        axios
          .get(GET_USER_MODULES)
          .then((result) => {
            setIsLoading(false);
            setUserModules(result.data);
          })
          .catch((err) => {
            console.log(err.message);
            setIsLoading(false);
          });
      })
      .catch((err) => {
        if (err.response.status === 401) navigate("/");
        console.log(err.message);
        setIsLoading(false);
      });
  }

  async function createUserRole() {
    if (newRoleValues.current.roleName.length == 0) return;

    setIsCreating(true);
    setIsUserRoleListChanged(true);
    let payload = await encryptStringInChunks(newRoleValues.current);
    axios
      .post(ADD_USER_ROLE, { payload })
      .then(async (res1) => {
        let roleName = newRoleValues.current.roleName;
        let roles = {};
        roles[roleName] = [0, 0, 0];
        let payload = await encryptStringInChunks({ roles: roles });
        axios
          .post(ADD_USER_ROLE_MODULE_PERMISSION, { payload })
          .then((res) => {
            setUserRoles((old) => [...old, res1.data]);
            setIsCreating(false);
            closeCreateRoleDialog();
            setIsUserRoleListChanged(false);
            window.location.reload();
          })
          .catch((e) => {
            console.log(e.message);
          });
      })
      .catch((e) => {
        console.log(e.message);

        if (e.response.status == 409) {
          messageApi.open({
            type: "error",
            content: "User Role Already Exist",
          });
          setCreateRoleDialogOpen(false);
          setTimeout(() => { }, 1000);
          window.location.reload();
        }
        setIsCreating(false);
      });
  }

  async function deleteUserRole(roleName) {
    let payload = await encryptStringInChunks({ roleName: roleName });
    axios
      .post(DELETE_USER_ROLE, { payload })
      .then((res) => {
        setIsUserRoleListChanged(false);
        window.location.reload();
        // setUserRoles(old => old.filter(r => r.roleName != roleName));
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  useEffect(() => {
    if (!localStorage.getItem("adToken")) navigate("/");
    document.title = "User Roles";
    fetchUserRoles();
  }, []);

  function openCreateRoleDialog() {
    setCreateRoleDialogOpen(true);
  }
  function closeCreateRoleDialog() {
    setCreateRoleDialogOpen(false);
  }

  function handleNewRoleValueChange(e) {
    let temp = newRoleValues.current;

    temp[e.target.name] = e.target.value;

    newRoleValues.current = temp;
  }

  return (
    <Box sx={{ height: "100%" }}>
      {contextHolder}
      <Dialog open={createRoleDialogOpen}>
        <DialogTitle>Create New Role</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              label={"Role Name"}
              onChange={handleNewRoleValueChange}
              name={"roleName"}
              fullWidth
            />
            <br />
            <br />
            <TextField
              label={"Role Description"}
              onChange={handleNewRoleValueChange}
              name={"desc"}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateRoleDialog} disabled={isCreating}>
            Cancel
          </Button>
          <LoadingButton onClick={createUserRole} loading={isCreating}>
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
          alignItems: "center",
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        <Box>
          <h1>User Roles</h1>
          <Typography component={"div"} variant={"p"}>
            Role is a set of permissions dealing with module access and
            operations, setup customizations, and access to various apps. You
            can provide different set of permissions to various users.
          </Typography>
          <br />
        </Box>
        <Box sx={{ minWidth: 150 }}>
          <Button variant={"contained"} onClick={openCreateRoleDialog}>
            Add New Role
          </Button>
        </Box>
      </Box>

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {columnHeads.map((h) => {
                return <TableCell>{h}</TableCell>;
              })}
              <TableCell align="right">Action(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isUserRoleListChanged && Array.isArray(userRoles) && (
              <>
                {userRoles.map((role) => {
                  return userModules.length != 0 ? (
                    <Row
                      deleteUserRole={deleteUserRole}
                      data={role}
                      userModules={userModules}
                      userRoles={userRoles}
                      setSaveChangeData={setSaveChangeData}
                      saveChangeData={saveChangeData}
                    />
                  ) : (
                    <></>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row(props) {
  const [data, setData] = useState(props.data);
  const { saveChangeData, setSaveChangeData } = props;
  let [messageApi, contextHolder] = message.useMessage();
  const [userRoles, setUserRoles] = useState(props.userRoles);
  const [userModules, setUserModules] = useState(props.userModules);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [somethingChanged, setSomethingChanged] = useState(false);
  const [deleteUserRoleDialog, setDeleteUserRoleDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  let [modulePermissionObj, getModulePermission] = useState({});

  function toggleAccordion() {
    setIsAccordionOpen((old) => !old);
  }

  useEffect(() => { }, []);

  function handleModuleAccessChange(view, edit, del) {
    setSomethingChanged(true);
  }

  async function saveChanges(data) {
    setIsSaving(true);
    modulePermissionObj.roleName = data.roleName;
    if (Object.keys(modulePermissionObj).length > 1) {
      let payload = await encryptStringInChunks({
        data: modulePermissionObj,
        _id: data._id,
      });
      axios
        .post(UPDATE_USER_ROLE_MODULE_PERMISSION, {
          payload,
        })
        .then((res) => {
          messageApi.open({
            type: "success",
            content: "Changes saved successfully",
          });
          setIsSaving(false);
          setSomethingChanged(false);
          modulePermissionObj = {};
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err.message);
        });
    }
    if (saveChangeData != undefined) {
      let payload = await encryptStringInChunks({ data: saveChangeData });
      axios
        .post(UPDATE_USER_ROLE_FIELD_PERMISSION, { payload })
        .then((res) => {
          setIsSaving(false);
          setSomethingChanged(false);
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err.message);
        });
    }
  }
  let counting = 0;
  let loopToFilterData = (m, roleName) => {
    let a = [];
    let rolePermissions = [];
    for (let i = 0; i < userRoles.length; i++) {
      counting++;
      if (userRoles[i].roleName == roleName) {
        rolePermissions = userRoles[i].perms[0];
        break;
      }
    }
    if (rolePermissions.hasOwnProperty("Fee Groups")) {
      if (m["moduleName"] == "Fee Groups") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Symbols")) {
      if (m["moduleName"] == "Symbols") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("User Roles")) {
      if (m["moduleName"] == "User Roles") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Users")) {
      if (m["moduleName"] == "Users") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("SMTP Setup")) {
      if (m["moduleName"] == "SMTP Setup") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Holding Cost Rebate")) {
      if (m["moduleName"] == "Holding Cost Rebate") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Open Position")) {
      if (m["moduleName"] == "Open Position") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Holding Cost Dashboard")) {
      if (m["moduleName"] == "Holding Cost Dashboard") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("File Uploader Dashboard")) {
      if (m["moduleName"] == "File Uploader Dashboard") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Commission & Spread")) {
      if (m["moduleName"] == "Commission & Spread") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Centra")) {
      if (m["moduleName"] == "Centra") {
        a.push(m);
      }
    }
    if (rolePermissions.hasOwnProperty("Symbol Master Uploader")) {
      if (m["moduleName"] == "Symbol Master Uploader") {
        a.push(m);
      }
    }
    console.log(a, "This is the Module list");
    return a;
  };
  let fileteredData = (m, roleName) => {
    let a = [];
    a = loopToFilterData(m, roleName);
    console.log("This is the couting ", counting);
    return a;
  };
  function closeDeleteRoleDialog() {
    setDeleteUserRoleDialog(false);
  }
  function deleteUser() {
    props.deleteUserRole(data.roleName);
  }
  return (
    <React.Fragment>
      <CustomDialog
        message="Are you sure you want to delete this role?"
        open={deleteUserRoleDialog}
        onClose={closeDeleteRoleDialog}
        customAction={deleteUser}
      />
      {contextHolder}
      <TableRow sx={{ "& td": { border: 0 } }}>
        <TableCell sx={{ width: "30px" }}>
          <IconButton size="small" onClick={toggleAccordion}>
            {isAccordionOpen ? (
              <KeyboardArrowDownIcon size="small" />
            ) : (
              <KeyboardArrowUpIcon size="small" />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <span>{data.roleName}</span>
            <span style={{ fontSize: "12px", color: "gray" }}>{data.desc}</span>
          </Box>
        </TableCell>
        <TableCell>
          {moment(data.createdAt).format("DD-MM-yyyy")}
          <br />
          <span style={{ color: "gray", fontSize: "12px" }}>
            {moment(data.createdAt).format("hh:mm A")}
          </span>
        </TableCell>
        <TableCell>
          {moment(data.updatedAt).format("DD-MM-yyyy")}
          <br />
          <span style={{ color: "gray", fontSize: "12px" }}>
            {moment(data.updatedAt).format("hh:mm A")}
          </span>
        </TableCell>

        <TableCell style={{ display: "flex", justifyContent: "end" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", width: "fit-content" }}
          >
            <IconButton
              size="small"
              onClick={(e) => {
                setDeleteUserRoleDialog(true);
              }}
            >
              <DeleteOutline size="small" />
            </IconButton>
            <IconButton
              disabled={!somethingChanged}
              onClick={() => saveChanges(data)}
            >
              <Save size="small" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={isAccordionOpen} timeout="auto" unmountOnExit>
            {userModules.map((m) => {
              {
                console.log(m, "Structure of M");
              }
              return (
                <ModuleAccessComponent
                  key={m.key}
                  reRenderPage={() => {
                    // console.log("Rendering now");
                    setSomethingChanged(true);
                    // console.log("executing now");
                  }}
                  handleModuleAccessChange={handleModuleAccessChange}
                  selectedRole={data}
                  userRoles={userRoles}
                  setSaveChangeData={setSaveChangeData}
                  modulePermissionObj={modulePermissionObj}
                  module={fileteredData(m, data.roleName)}
                />
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
