import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GlobalError } from "./globalError";

export default function PopularProductRank(props) {
    const { popular_product_rank, sr_no, duplicateSymbolObject } = props;
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [elementId, setElementId] = useState(popular_product_rank + "" + sr_no + "_popular_product_rank");

    useEffect(() => {
        try {
            if (duplicateSymbolObject[sr_no - 1] == true) {
                setIsError(true);
                setErrMsg("Duplicate Rank");
            }
            GlobalError(popular_product_rank, setIsError, setErrMsg)
        } catch (e) {
            // setErrors(old => [...old, elementId]);
            setIsError(true);
        }
    }, []);

    return (
        <>
            {isError && (
                <Tooltip title={errMsg ? errMsg : "Popular product rank can't be empty"}>
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <Space>
                            <span style={{ color: "red" }}>{popular_product_rank}</span>
                            <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
                        </Space>
                    </div>
                </Tooltip>
            )}
            {!isError && (
                <div id={elementId} style={{ paddingLeft: 4 }}>
                    <span style={{ color: "black" }}>{popular_product_rank}</span>
                </div>
            )}
        </>
    );
}
