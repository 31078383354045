import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { GlobalError } from "./globalError";

export default function ContractSpecs(props) {
    const { contract_specs, sr_no } = props;
    const [isError, setIsError] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [elementId, setElementId] = useState(contract_specs + "" + sr_no + "_contract_specs");

    useEffect(() => {
        try {
            // if (!contract_specs) {
            //     console.log(contract_specs)
            //     //    setErrors(old => [...old, elementId]);
            //     setIsError(true);
            // }
            GlobalError(contract_specs, setIsError, setErrMsg)
        } catch (e) {
            // setErrors(old => [...old, elementId]);
            setIsError(true);
        }
    }, []);

    return (
        <>
            {isError && (
                <Tooltip title={errMsg ? errMsg : "Contract Specs can't be empty"}>
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <Space>
                            <span style={{ color: "red" }}>{contract_specs}</span>
                            <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
                        </Space>
                    </div>
                </Tooltip>
            )}
            {!isError && (
                <div id={elementId} style={{ paddingLeft: 4 }}>
                    <span style={{ color: "black" }}>{contract_specs}</span>
                </div>
            )}
        </>
    );
}
